import { Cayan } from "./Cayan";
import { Clearent } from "./Clearent";
import { Payroc } from "./Payroc";
import { PPI } from "./PPI";
import { Utils } from "./Utils";
import { PaymentGatewaySettings } from "./PaymentGatewaySettings";
import { IntiatedPaymentViewModel } from "./InitiatedPaymentViewModel";
import { CheckTransactionViewModel } from "./ViewModels/CheckTransactionViewModel";
import { SavedCardTransactionViewModel } from "./ViewModels/SavedCardTransactionViewModel";

export class PaymentGateway {
  // ReSharper disable InconsistentNaming
  Cayan = new Cayan();
  Clearent = new Clearent();
  Payroc = new Payroc();
  PPI = new PPI();
  Utils = new Utils();
  // ReSharper restore InconsistentNaming

  clearAttemptedPayment(
    intiatedPaymentViewModel: IntiatedPaymentViewModel,
    isBusy: (b: boolean) => any,
    forgeryToken: string,
    onSuccess: (reply: any) => any,
    onError: (reply: any) => any
  ) {
    const ajaxSettings: any = {
      type: "POST",
      url: "/PaymentGateway/ClearAttemptedPayment",
      contentType: "application/json",
      data: JSON.stringify(intiatedPaymentViewModel),
      success: (reply) => {
        if (reply.isRedirect) {
          window.location.href = reply.redirectUrl;
        } else {
          if (reply) {
            if (onError && reply.error) {
              onError(reply);
            }
            if (onSuccess) {
              onSuccess(reply);
            }
          }
        }
      },
      error: (err) => {
        console.log(err.status + " : " + err.statusText);
      },
      always: () => {
        if (isBusy) {
          isBusy(false);
        }
      },
    };
    $.ajax(ajaxSettings);
  }

  createSettingsForPayroc(
    enableAvs: boolean,
    hostUrl: string,
    hppPublicKey: string
  ): PaymentGatewaySettings {
    return new PaymentGatewaySettings(enableAvs, "", hostUrl, hppPublicKey, "");
  }

  getPaymentFromChargeKey(
    intiatedPaymentViewModel: IntiatedPaymentViewModel,
    isBusy: (b: boolean) => any,
    forgeryToken: string,
    onSuccess: (reply: any) => any,
    onError: (reply: any) => any
  ): void {
    const ajaxSettings: any = {
      type: "POST",
      url: "/PaymentGateway/GetPaymentFromChargeKey",
      contentType: "application/json",
      data: JSON.stringify(intiatedPaymentViewModel),
      success: (reply) => {
        if (reply.isRedirect) {
          window.location.href = reply.redirectUrl;
        } else {
          if (reply) {
            if (onError && reply.error) {
              onError(reply);
            }
            onSuccess(reply);
          }
        }
      },
      error: (err) => {
        console.log(err.status + " : " + err.statusText);
      },
      always: () => {
        if (isBusy) {
          isBusy(false);
        }
      },
    };
    $.ajax(ajaxSettings);
  }

  performCheckTransaction(
    checkTransactionViewModel: CheckTransactionViewModel,
    isBusy: (b: boolean) => any,
    forgeryToken: string,
    onSuccess: (reply: any) => any,
    onError: (reply: any) => any
  ): void {
    const ajaxSettings: any = {
      type: "POST",
      url: "/PaymentGateway/PerformCheckTransaction",
      contentType: "application/json",
      data: JSON.stringify(checkTransactionViewModel),
      success: (reply) => {
        if (reply.isRedirect) {
          window.location.href = reply.redirectUrl;
        } else {
          if (reply) {
            if (onError && reply.error) {
              onError(reply);
            } else {
              onSuccess(reply);
            }
          }
        }
      },
      error: (err) => {
        if (err.responseJSON) {
          if (onError) {
            onError(err.responseJSON);
          } else {
            console.log(err.status + " : " + err.statusText);
          }
        }
      },
      always: () => {
        if (isBusy) {
          isBusy(false);
        }
      },
    };
    $.ajax(ajaxSettings);
  }

  applyPaymentsManually(
    viewModelExternal: SavedCardTransactionViewModel,
    isBusy: (b: boolean) => any,
    forgeryToken: string,
    onError: (reply: any) => any,
    returnUrl: string
  ) {
    var viewModel: SavedCardTransactionViewModel =
      new SavedCardTransactionViewModel(viewModelExternal);
    viewModel.companyName = localStorage.CompanySettings
      ? JSON.parse(localStorage.CompanySettings).CompanyName
      : "";

    if (viewModel.chargeKey) {
      this.postApplyPaymentManually(viewModel, isBusy, onError, returnUrl);
    } else {
      const ajaxSettings: any = {
        type: "POST",
        url: "/PaymentGateway/CreateSavedCardTransaction",
        contentType: "application/json",
        data: JSON.stringify(viewModel),
        success: (reply) => {
          if (reply.isRedirect) {
            window.location.href = reply.redirectUrl;
          } else {
            if (reply) {
              if (onError && reply.error) {
                onError(reply);
              } else {
                viewModel.chargeKey = "";
                if (reply.result && reply.result.chargeKey) {
                  viewModel.chargeKey = reply.result.chargeKey;
                }
                if (reply.result && reply.result.dispatchModel) {
                  viewModel.dispatch = reply.result.dispatchModel;
                }
                this.postApplyPaymentManually(
                  viewModel,
                  isBusy,
                  onError,
                  returnUrl
                );
              }
            }
          }
        },
        error: (err) => {
          console.log(err.status + " : " + err.statusText);
        },
        always: () => {
          if (isBusy) {
            isBusy(false);
          }
        },
      };
      $.ajax(ajaxSettings);
    }
  }

  private postApplyPaymentManually(
    viewModel: SavedCardTransactionViewModel,
    isBusy: (b: boolean) => any,
    onError: (reply: any) => any,
    returnUrl: string
  ): void {
    const ajaxSettings: any = {
      type: "POST",
      url: "/PaymentGateway/ApplyPaymentManually",
      contentType: "application/json",
      data: JSON.stringify(viewModel),
      success: (reply) => {
        if (reply.isRedirect) {
          window.location.href = reply.redirectUrl;
        } else {
          if (reply) {
            window.location.href = returnUrl;
          }
        }
      },
      error: (err) => {
        console.log(err.status + " : " + err.statusText);
      },
      always: () => {
        if (isBusy) {
          isBusy(false);
        }
      },
    };
    $.ajax(ajaxSettings);
  }
}
