import $ from "jquery";

export class Utils {

    checkForSuccessMessage(
        paymentSuccessfulMessage: (amount: string, authCode: string) => any ) {
        $(document).ready(function () {

            var retrievedObject = localStorage.getItem('paymentResponse');
            if (retrievedObject) {

                localStorage.removeItem("paymentResponse");

                var paymenetResponse = JSON.parse(retrievedObject);
                var secondsPassed = (new Date().getTime() - paymenetResponse.when) / (1000);
                if (secondsPassed < 20) {
                    setTimeout(function () {//Give some time for the UI to be ready.
                        paymentSuccessfulMessage(paymenetResponse.amount, paymenetResponse.authCode);
                    }, 3000);
                }
            }

        });

    }

}