import $ from "jquery";
import * as moment from "moment";
import { PaymentGatewayProvider, PaymentGatewaySettings } from "./PaymentGatewayProvider";
import { BaseViewModel } from "./ViewModels/BaseViewModel";

class GetChargeKeyViewModel extends BaseViewModel {
    ppiReference: string;
    name: string;
    
    
    methodId: string;
    primaryBillEntityId: string;
    schedulingMissedAppointment: boolean;
    



    constructor(viewModel: GetChargeKeyViewModel) {

        super(viewModel);
        this.ppiReference = viewModel.ppiReference;
        
        this.name = viewModel.name;
        this.methodId = viewModel.methodId;

        this.primaryBillEntityId = viewModel.primaryBillEntityId;
        this.schedulingMissedAppointment = viewModel.schedulingMissedAppointment;

        
        
    }
}


export class PPI implements PaymentGatewayProvider {

    enterPaymentsManually(viewModelExternal: GetChargeKeyViewModel,
        paymentGatewaySettings: PaymentGatewaySettings,
        fixUrlForQuerystring: boolean,
        isBusy: (b: boolean) => any,
        onError: (reply: any) => any) {

        var viewModel: GetChargeKeyViewModel = new GetChargeKeyViewModel(viewModelExternal);
        

        var amount = viewModel.amount.toFixed(2);
        
        var ajaxSettings = {
            type: 'GET',
            url: '/Dispatch/GetChargeKey',
            contentType: 'application/json',
            data: { seller_id: paymentGatewaySettings.PPISellerID, Reference: viewModel.ppiReference, Amount: amount },
            dataType: 'json',
            success: function (reply) {
                if (reply.isRedirect) {
                    window.location.href = reply.redirectUrl;
                }
                else {
                    if (reply) {
                        if (reply.error) {
                            onError(reply);
                            //self.MessageType(1);
                            //self.Message(reply.message);
                        }
                        else {
                            var url = window.location.href;
                            if (fixUrlForQuerystring ) {
                                var addressPos = url.indexOf('?');//Only customer screen has the Id
                                if (addressPos == -1) {
                                    var questionNeeded = true;
                                    if (url.indexOf('?id=') != -1) {
                                        addressPos += 40;
                                        questionNeeded = false;
                                    }

                                    url = url.substring(0, addressPos == -1 ? url.length : addressPos);

                                    if (questionNeeded)
                                        url += '?';
                                    else {
                                        url += '&';
                                    }
                                }
                                else {
                                    url += '&';
                                }
                            }
                            else {
                                url += "&";
                            }

                            url = url.replace('/Details', '/DetailsAnon');
                          
                            var PPITransaction =
                                {
                                   
                                    RequestURL: paymentGatewaySettings.PPISellerID.startsWith('9') ? "https://test.ekashu.com" : "https://live.ekashu.com",
                                    UseStaging: false,
                                    DuplicateCheck: false,
                                    Mobile: false,
                                    seller_id: paymentGatewaySettings.PPISellerID,
                                    seller_key: paymentGatewaySettings.PPISellerKey.substring(0, 8),
                                    data: { seller_id: paymentGatewaySettings.PPISellerID, Reference: viewModel.ppiReference, Amount: amount },
                                    Title: '',
                                    OrderNumber: '',
                                    CardholderAddress: '',
                                    CardholderZip: '',
                                    CardholderName: '',
                                    ChargingCompanyName: '',
                                    CardTitleMandatory: false,
                                    ReturnButtomText: "Cancel",
                                    CardEmailAddressMandatory: false,
                                    CardholderLastName: '',
                                    CardholderFirstName: '',
                                    ReturnURL: url + "section=4&status=cancelled&Integration=ppi",
                                    FailureURL: url + "section=4&status=failure&Integration=ppi",
                                    SuccessURL: url + "section=4&status=success&Integration=ppi",
                                    ChargeKey: reply.result
                                }
                            

                            var AVS: boolean = localStorage.CompanySettings ? JSON.parse(localStorage.CompanySettings).PPIEnableAddressVerify : false;

                            //Form declaration

                            var form = $(document.createElement('form'));
                            $(form).attr("action", PPITransaction.RequestURL);
                            $(form).attr("method", "POST");



                            //HashCode
                            if (reply.hashCode) {
                                $(form).append($("<input>").attr("type", "hidden").attr("name", "ekashu_hash_code").val(reply.hashCode));
                            }


                            //SellerID
                            $(form).append($("<input>").attr("type", "hidden").attr("name", "ekashu_seller_id").val(PPITransaction.seller_id));

                            //SellerKey
                            $(form).append($("<input>").attr("type", "hidden").attr("name", "ekashu_seller_key").val(PPITransaction.seller_key));

                            //Amount
                            $(form).append($("<input>").attr("type", "hidden").attr("name", "ekashu_amount").val(amount));

                            //Currency
                            $(form).append($("<input>").attr("type", "hidden").attr("name", "ekashu_currency").val('840'));

                            //Cancel 
                            $(form).append($("<input>").attr("type", "hidden").attr("name", "ekashu_return_url").val(PPITransaction.ReturnURL));

                            //failure 
                            $(form).append($("<input>").attr("type", "hidden").attr("name", "ekashu_failure_url").val(PPITransaction.FailureURL));

                            //success 
                            $(form).append($("<input>").attr("type", "hidden").attr("name", "ekashu_success_url").val(PPITransaction.SuccessURL));

                            //ViewPort
                            $(form).append($("<input>").attr("type", "hidden").attr("name", "ekashu_viewport").val('width=device-width, initial-scale=.75, maximum-scale=1.0, userscalable=no'));

                            //Styles
                            $(form).append($("<input>").attr("type", "hidden").attr("name", "ekashu_style_sheet").val('https://login.fieldedge.com/Content/PPI/ppi_css_style.css'));

                            //card_title-mandatory
                            $(form).append($("<input>").attr("type", "hidden").attr("name", "ekashu_card_title_mandatory").val(PPITransaction.CardTitleMandatory.toString()));

                            //Cancel Button Text
                            $(form).append($("<input>").attr("type", "hidden").attr("name", "ekashu_return_text").val(PPITransaction.ReturnButtomText));

                            //Email Mandatory
                            $(form).append($("<input>").attr("type", "hidden").attr("name", "ekashu_card_email_address_mandatory").val(PPITransaction.CardEmailAddressMandatory.toString()));

                            //CompanyName
                            $(form).append($("<input>").attr("type", "hidden").attr("name", "CompanyName").val(localStorage.CompanySettings ? JSON.parse(localStorage.CompanySettings).CompanyName : ''));

                            //ChargeKey
                            $(form).append($("<input>").attr("type", "hidden").attr("name", "ChargeKey").val(PPITransaction.ChargeKey));

        

                            //EntityID
                            $(form).append($("<input>").attr("type", "hidden").attr("name", "EntityID").val(viewModel.entityId));

                            //EntityID
                            $(form).append($("<input>").attr("type", "hidden").attr("name", "SelectedPaymentMethodID").val(viewModel.methodId));

                            //PrimaryBillEntityID
                            $(form).append($("<input>").attr("type", "hidden").attr("name", "PrimaryBillEntityID").val(viewModel.primaryBillEntityId));

                            //Auto confirm 
                            $(form).append($("<input>").attr("type", "hidden").attr("name", "ekashu_auto_confirm").val(true.toString()));

                            //ekashu_valid_from_required 
                            $(form).append($("<input>").attr("type", "hidden").attr("name", "ekashu_valid_from_required").val(false.toString()));

                            //SavePaymentForLater
                            $(form).append($("<input>").attr("type", "hidden").attr("name", "SavePaymentForLater").val(viewModel.saveForFutureTransaction.toString()));

                            ////card_address Mandatory
                            $(form).append($("<input>").attr("type", "hidden").attr("name", "ekashu_card_address_required").val(true.toString()));

                            //-------------------------------------------------------------------------------------------------------------------------------------

                            //card_address verify
                            $(form).append($("<input>").attr("type", "hidden").attr("name", "ekashu_card_address_verify").val(AVS.toString()));

                            //card_zip_code_verify Mandatory
                            $(form).append($("<input>").attr("type", "hidden").attr("name", "ekashu_card_zip_code_verify").val(AVS.toString()));

                            //CVV / CSC number 
                            $(form).append($("<input>").attr("type", "hidden").attr("name", "ekashu_verification_value_verify").val("check"));

                            //-------------------------------------------------------------------------------------------------------------------------------------

                            //Reference Fields
                            //ekashu_description 
                            $(form).append($("<input>").attr("type", "hidden").attr("name", "ekashu_description").val("Payment for " + viewModel.ppiReference));

                            //ekashu_Reference
                            $(form).append($("<input>").attr("type", "hidden").attr("name", "ekashu_reference").val(viewModel.ppiReference));

                            //ekashu_duplicate_minutes
                            $(form).append($("<input>").attr("type", "hidden").attr("name", "ekashu_duplicate_minutes").val(5));

                            //Duplicate check 
                            $(form).append($("<input>").attr("type", "hidden").attr("name", "ekashu_duplicate_check").val("error"));

                            if (viewModel.isAgreement) {
                                //CustomerName
                                $(form).append($("<input>").attr("type", "hidden").attr("name", "CustomerName").val(viewModel.name));

                                //AgreementID
                                $(form).append($("<input>").attr("type", "hidden").attr("name", "AgreementID").val(viewModel.agreementId));

                                //RecurranceID
                                $(form).append($("<input>").attr("type", "hidden").attr("name", "RecurranceID").val(viewModel.recurranceId));

                                //Is Missed
                                $(form).append($("<input>").attr("type", "hidden").attr("name", "IsMissed").val(viewModel.schedulingMissedAppointment.toString()));
                            }
                            else if (viewModel.isDispatch) {
                                //DispatchID
                                $(form).append($("<input>").attr("type", "hidden").attr("name", "DispatchID").val(viewModel.dispatchId));

                                //DispatchTaskID
                                $(form).append($("<input>").attr("type", "hidden").attr("name", "DispatchTaskID").val(viewModel.dispatchTaskId));

                                //PaymentIndex
                                $(form).append($("<input>").attr("type", "hidden").attr("name", "PaymentIndex").val(String(viewModel.paymentIndex)));

                                //DispatchName
                                $(form).append($("<input>").attr("type", "hidden").attr("name", "DispatchName").val(viewModel.dispatchName));

                                //transaction Date
                                $(form).append($("<input>").attr("type", "hidden").attr("name", "PaymentTransactionDate").val(moment.utc().format("MM/DD/YYYY HH:mm ss")));

                                //Store on LocalStorage
                                localStorage.PaymentTransactionDate = moment.utc().format("MM/DD/YYYY HH:mm ss");
                            }

                            form.appendTo(document.body);

                            $(form).submit();

                        }
                    }
                }

            },

            error: function (err) {
                console.log(err.status + ' : ' + err.statusText);
            },
            always: function () {
                if (isBusy) {
                    isBusy(false);
                }
                //self.IsBusy(false);
            }

        };

        $.ajax(ajaxSettings);
    }
}

