export class UrlHelper {
  static getParameterByName(
    name: string,
    url: string | null = null
  ): string | null {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  static post(path: string, params: Map<string, string>) {
    //method = method || "post"; // Set method to post by default if not specified.

    // The rest of this code assumes you are not using a library.
    // It can be made less wordy if you use one.
    var form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", path);

    if (params) {
      params.forEach((value, key) => {
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute("value", value);

        form.appendChild(hiddenField);
      });
    }

    document.body.appendChild(form);
    form.submit();
  }

  static containsQueryString(url: string | null = null): boolean {
    if (!url) url = window.location.href;
    return url.lastIndexOf("?") > -1;
  }
}
