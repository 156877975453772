export class BaseViewModel {

    dispatchId: string;

    agreementId: string;
    
    amount: number;
    companyName: string;
    customerName: string;
    isMissed: boolean;

    //used on customer screen
    recurranceId: string;
    entityId: string;
    selectedPaymentMethodId: string;
    agreementName: string;
    dispatchTaskId: string;
    
    paymentIndex: string;
    dispatchName: string;
    saveForFutureTransaction: boolean;
    invoiceNumber:string;

    //Used to continue Agreement Payments
    chargeKey: string;

    //Used to skip invoice old business logic
    isNewWorkOrderPageRedesign: boolean

    public get isAgreement(): boolean {
        return this.agreementId != undefined;
    }


    public get isDispatch(): boolean {
        return this.dispatchId != undefined;
    }

    public get fromName(): string {
        return this.isAgreement ? "Agreement" : "Work Order";
    }

    public get fromValue(): string {
        return this.isAgreement ? this.agreementName : this.dispatchName;
    }
    
    constructor(viewModel: BaseViewModel) {
        this.dispatchId = viewModel.dispatchId;
        this.dispatchTaskId = viewModel.dispatchTaskId;

        this.agreementId = viewModel.agreementId;


        this.amount = viewModel.amount;
        this.companyName = viewModel.companyName;
        this.customerName = viewModel.customerName;
        this.isMissed = viewModel.isMissed;

        //used on customer screen
        this.recurranceId = viewModel.recurranceId;
        this.entityId = viewModel.entityId;
        this.selectedPaymentMethodId = viewModel.selectedPaymentMethodId;
        this.agreementName = viewModel.agreementName;

        //used by dispatch
        this.paymentIndex = viewModel.paymentIndex;
        this.dispatchName = viewModel.dispatchName;
        this.invoiceNumber = viewModel.invoiceNumber;

        this.saveForFutureTransaction = viewModel.saveForFutureTransaction;

        //Used to continue Agreement Payments
        this.chargeKey = viewModel.chargeKey;

        this.isNewWorkOrderPageRedesign = viewModel.isNewWorkOrderPageRedesign;
    }
}