export class PaymentGatewaySettings {
    private _enableAvs: boolean;
    private _gatewayUrl: string;
    private _hostUrl: string;
    private _hppPublicKey: string;
    private _hppPublicKeyOnline: string;

    public get enableAvs() { return this._enableAvs }
    public get gatewayUrl() { return this._gatewayUrl }
    public get hostUrl() { return this._hostUrl }
    public get hppPublicKey() { return this._hppPublicKey }
    public get hppPublicKeyOnline() { return this._hppPublicKeyOnline }

    constructor(
        enableAvs: boolean,
        gatewayUrl: string,
        hostUrl: string,
        hppPublicKey: string,
        hppPublicKeyOnline: string
    ) {
        this._enableAvs = enableAvs;
        this._gatewayUrl = gatewayUrl;
        this._hostUrl = hostUrl;
        this._hppPublicKey = hppPublicKey;
        this._hppPublicKeyOnline = hppPublicKeyOnline;
    }
}
