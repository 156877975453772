import { BaseViewModel } from "./BaseViewModel";

export class SavedCardTransactionViewModel extends BaseViewModel {
    descriptor: string;
    paymentType: Number;
    //needed to save dispatch
    groups: object[];
    dispatch: object;
    taxCode: object;
    salesTaxCodes: object[];
    laborEmployeeList: object[];
    removedTimeSheets: object[];

    customerPaymentInfo: object;
    WorkOrder: object;
    
    
    constructor(savedCardTransactionViewModel: SavedCardTransactionViewModel) {
        super(savedCardTransactionViewModel);

        this.descriptor = savedCardTransactionViewModel.descriptor;
        //needed to save dispatch
        this.groups = savedCardTransactionViewModel.groups;
        this.dispatch = savedCardTransactionViewModel.dispatch;
        this.taxCode = savedCardTransactionViewModel.taxCode;
        this.salesTaxCodes = savedCardTransactionViewModel.salesTaxCodes;
        this.laborEmployeeList = savedCardTransactionViewModel.laborEmployeeList;
        this.removedTimeSheets = savedCardTransactionViewModel.removedTimeSheets;
        this.customerPaymentInfo = savedCardTransactionViewModel.customerPaymentInfo;
        this.paymentType = savedCardTransactionViewModel.paymentType;
        this.WorkOrder = savedCardTransactionViewModel.WorkOrder;
    }
}