import { BaseViewModel } from "../ViewModels/BaseViewModel"

export class ClearentViewModel extends BaseViewModel {
    header: string;

    //fromValue: string;

    //needed to save dispatch
    groups: object[];
    dispatch: object;
    taxCode: object;
    salesTaxCodes: object[];
    laborEmployeeList: object[];
    removedTimeSheets: object[];
    WorkOrder: object


    constructor(viewModel: ClearentViewModel) {

        super(viewModel);
        this.header = viewModel.header;

        //needed to save dispatch
        this.groups = viewModel.groups;
        this.dispatch = viewModel.dispatch;
        this.taxCode = viewModel.taxCode;
        this.salesTaxCodes = viewModel.salesTaxCodes;
        this.laborEmployeeList = viewModel.laborEmployeeList;
        this.removedTimeSheets = viewModel.removedTimeSheets;
        this.WorkOrder = viewModel.WorkOrder;       
    }
}